<template>
  <div class="my">
    <nav-bar show-back="1">
      <div class="nav-title">GRUP SAYA</div>
    </nav-bar>

    <tabs :tab="tab" @change="setTab"></tabs>

    <div class="list" v-if="list && currentGroups.length > 0">
      <div v-for="(item, index) of currentGroups" :key="index">
        <div class="date" v-if="item.date">{{item.date}}</div>
        <coupon-item :info="item" v-else></coupon-item>
      </div>
    </div>

    <template v-if="list && currentGroups.length <= 0">
      <div class="empty">Mohon maaf grup gagal terbentuk silahkan<br/> untuk mencoba lagi</div>
    </template>

    <div class="iphone-x"></div>

    <bottom isactive="3"></bottom>
  </div>
</template>

<script>
  import {filter} from 'ramda'
  import groupHandler from '../../mixins/groupHandler'
  //filter
  import formatDate from '../../filters/formatDate'
  //component
  import NavBar from '../../components/navBar'
  import Tabs from './components/tabs'
  import CouponItem from './components/couponItem'
  import Bottom from '../../components/bottom'
  //api
  import service from '../../service'

  export default {
    data() {
      return {
        list: false,
        tab: 0
      }
    },
    mixins: [
      groupHandler
    ],
    components: {
      NavBar,
      Tabs,
      CouponItem,
      Bottom
    },
    computed: {
      currentGroups() {
        let groups = []

        if (this.list && this.list.length > 0) {
          groups = filter((item) => {
            let result = false

            //所有商品
            if (this.tab == 0) {
              result = true
            }
            //进行中的商品
            else if (this.tab == 1 && item.status == 0) {
              result = true
            }
            //成功
            else if (this.tab == 2 && item.status == 1) {
              result = true
            }
            //失败
            else if (this.tab == 3 && item.status > 1) {
              result = true
            }

            return result
          })(this.list)

          groups = formatGroups(groups)
        }

        return groups
      }
    },
    methods: {
      init() {
        this.$getAuth().then(() => {
          //已登录
          this.getMyGroups();
        }).catch(() => {
          //未登录
          this.list = []
          this.$hideLoading()
        })
      },
      getMyGroups() {
        this.$getUserInfo().then(({userid}) => {
          service.getMyGroups(userid).then((data) => {
            this.list = data && data.groups || []
            //this.list = formatGroups()
          }).catch(() => {
            this.list = []
          }).then(this.$hideLoading)
        })
      },
      setTab(tab) {
        this.tab = tab
      }
    },
    created() {
      //添加登录成功监听
      this.$bus.$off('loginSuccess', this.init);
      this.$bus.$on('loginSuccess', this.init);

      this.$showLoading()
      this.init()

      window.track('wowsaa-groups', 'my', 'expose')
    },
    beforeDestroy() {
      this.$bus.$off('loginSuccess', this.init);
    }
  }

  function formatGroups(list) {
    let result = []
    let date = ''

    list.forEach((item) => {
      let d = formatDate(item.start_time).split(' ')[0]

      if (d != date) {
        date = d
        result.push({date: d})
      }

      result.push(item)
    })

    return result
  }
</script>

<style lang="scss" scoped>
  .my {
    padding-bottom: pxTo(48+20);

    @extend %page;
    background-color: $c1;

    .empty {
      width: 100%;
      padding-top: pxTo(60+34);
      text-align: center;

      @extend %v-center;
      @extend %ff-rm;
      @include fs-l-w-c(14, 16, 400, $c2);
      @include bg($img-base, 'img_mygroup_empty.svg');
      background-size: pxTo(120) pxTo(60);
      background-position: top center;
    }

    .list {
      margin-top: pxTo(40);

      .date {
        display: inline-block;
        height: pxTo(21);
        padding: 0 pxTo(8);
        margin-top: pxTo(12);
        margin-left: pxTo(15);
        margin-bottom: pxTo(12);
        text-align: center;
        border-radius: pxTo(10);
        background-color: $c7;

        @extend %ff-rm;
        @include fs-l-w-c(10, 21, 800, $c8);
      }
    }
  }
</style>