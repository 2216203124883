<template>
  <div class="tabs fx-row fx-center">
    <div class="tab fx-1" :class="{active:tab==0}" @click="onChange(0)">SEMUA</div>
    <div class="tab fx-1" :class="{active:tab==1}" @click="onChange(1)">BERLANGSUNG</div>
    <div class="tab fx-1" :class="{active:tab==2}" @click="onChange(2)">BERHAIL</div>
    <div class="tab fx-1" :class="{active:tab==3}" @click="onChange(3)">GAGAL</div>
  </div>
</template>

<script>
  export default {
    props: ['tab'],
    methods: {
      onChange(tab) {
        if (tab != this.tab) {
          this.$emit('change', tab)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tabs {
    width: 100%;
    height: pxTo(40);
    position: fixed;
    left: 0;
    top: pxTo(52);
    z-index: 10;
    background-color: $c22;

    .tab {
      height: pxTo(40);
      text-align: center;
      position: relative;

      @extend %ff-rr;
      @include fs-l-w-c(12, 40, 400, $c2);

      &.active {
        color: $c23;

        &::after {
          content: '';
          display: block;
          width: pxTo(42);
          height: pxTo(4);
          background-color: $c23;
          bottom: 0;

          @extend %h-center;
        }
      }
    }
  }
</style>