<template>
  <div class="item" :class="statusStyle" @click="gotoDetails">
    <div class="coupon fx-row fx-center">
      <div class="left" :style="{ backgroundImage: goodsThumb }"></div>
      <div class="right fx-1">
        <div class="title">{{ title }}</div>
        <div class="desc">{{ desc }}</div>
        <div class="price fx-row fx-v-center">
          Rp{{ formatSplitMoney(info.coupon_price) }}
          <div class="origin" v-if="info.origin_price">
            Rp{{ info.origin_price }}
          </div>
        </div>
      </div>
    </div>

    <div class="bottom fx-row">
      <!-- 立即购买 -->
      <div class="btn fx-1" v-if="status == 0" @click.stop="gotoShopify">
        <div>BELI SEKARANG</div>
      </div>

      <!-- 查看详情 -->
      <div class="btn fx-1" v-if="status > 1" @click.stop="gotoDetails">
        <div>LIHAT DETAILNYA</div>
      </div>

      <div class="split" v-if="status == 0 || status == 2"></div>

      <!-- 邀请好友 -->
      <div class="btn full fx-1" v-if="status == 0" @click.stop="onShare">
        <div>UNDANG TEMAN</div>
      </div>

      <!-- 重新发起拼团 -->
      <div class="btn full fx-1" v-if="status == 2" @click.stop="onStart">
        <div>BUAT GRUP BARU</div>
      </div>

      <!-- 复制券码并跳转到店铺 -->
      <div :id="id" class="btn full fx-1" v-if="status == 1" @click="onCopy">
        CEK BARANG DI TOKO
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from "@/utils/clipboard";
//filter
import formatDiscountRate from "../../../filters/formatDiscountRate";
import formatBackgroundImgUrl from "../../../filters/formatBackgroundImgUrl";
import formatSplitMoney from "../../../filters/formatSplitMoney";
//mixin
import groupHandler from "../../../mixins/groupHandler";

export default {
  props: ["info"],
  mixins: [groupHandler],
  computed: {
    id() {
      return `copyBtn${(this.info && this.info.id) || 0}`;
    },
    activityId() {
      return (this.info && this.info.activity_id) || 1;
    },
    status() {
      return (this.info && this.info.status) || 0;
    },
    statusStyle() {
      let style = "";

      if (this.status == 1) {
        style = "success";
      } else if (this.status == 2) {
        style = "fail";
      }

      return style;
    },
    goodsThumb() {
      let img = (this.info && this.info.goods_thumbnail) || "";
      return formatBackgroundImgUrl(img);
    },
    title() {
      return (this.info && this.info.coupon_title) || "";
    },
    desc() {
      return (this.info && this.info.coupon_desc) || "";
    },
    discountRate() {
      return formatDiscountRate((this.info && this.info.discount_rate) || 0);
    },
    lessNum() {
      return this.totalNum - ((this.info && this.info.current_members) || 0);
    },
    totalNum() {
      return (this.info && this.info.expected_members) || 0;
    },
    couponCode() {
      return (this.info && this.info.coupon_code) || "";
    },
  },
  methods: {
    formatSplitMoney,
    gotoShopify() {
      this.gotoPage("shopify", { url: this.info && this.info.goods_url });
    },
    gotoDetails(e) {
      if (!(e.target && e.target.id && e.target.id.indexOf("copyBtn") >= 0)) {
        this.gotoPage("invite", { id: this.info.id });
      }
    },
    onShare() {
      let url = `${location.protocol}//${location.host}/groups/invite?id=${this.info.id}&from=invite`;
      this.$bus.$emit("share", {
        url,
        info: {
          title: (this.info && this.info.coupon_title) || "",
          discount_rate: (this.info && this.info.discount_rate) || "",
          less: this.lessNum,
          total: this.totalNum,
          activityId: this.activityId,
        },
      });
    },
    onStart() {
      this.startNewGroup(this.activityId);
    },
    onCopy() {
      //复制并跳转到店铺
      if (this.couponCode && Clipboard && Clipboard.isSupported()) {
        let clipboard = new Clipboard(`#${this.id}`, {
          text: () => this.couponCode,
        });

        clipboard.on("success", () => {
          this.gotoShopify();
        });
      } else {
        this.gotoShopify();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  height: pxTo(220);
  padding: pxTo(10) pxTo(15) 0 pxTo(15);
  position: relative;

  @include bg($img-base, "my-coupon-bg.png");
  background-size: 114% auto;
  background-position: center pxTo(-23);

  &.success {
    &::before {
      content: "";
      display: block;
      width: pxTo(80);
      height: pxTo(80);
      position: absolute;
      right: pxTo(-12);
      top: pxTo(26);

      @include bg($img-base, "ic_mygroup_badge_success.svg");
      background-size: 100% 100%;
    }
  }

  &.fail {
    &::before {
      content: "";
      display: block;
      width: pxTo(80);
      height: pxTo(80);
      position: absolute;
      right: pxTo(-12);
      top: pxTo(26);

      @include bg($img-base, "ic_mygroup_badge_failed.svg");
      background-size: 100% 100%;
    }
  }

  .coupon {
    width: 100%;
    height: pxTo(115+13);
    padding-top: pxTo(9);

    .left {
      width: pxTo(105+7);
      height: 100%;
      position: relative;
      background-color: #f4f4f4;
      border-radius: pxTo(5) 0 0 pxTo(5);
      background-size: 100% auto !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
    }

    .right {
      padding-left: pxTo(12);
      padding-right: pxTo(12);

      .title {
        margin-bottom: pxTo(8);
        word-break: break-all;
        text-overflow: -o-ellipsis-lastline;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @extend %ff-rm;
        @include fs-l-w-c(14, 16, 500, $c9);
      }

      .desc {
        margin-bottom: pxTo(8);
        word-break: break-all;
        text-overflow: -o-ellipsis-lastline;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @extend %ff-rr;
        @include fs-l-w-c(12, 14, 400, $c10);
      }
      .price {
        @extend %ff-rr;
        @include fs-l-w-c(12, 14, 400, $c10);
      }

      .price {
        margin-top: pxTo(4);

        @extend %ff-rm;
        @include fs-l-w-c(20, 16, 500, $c16);

        .origin {
          text-decoration: line-through;
          margin-left: pxTo(10);

          @include fs-l-w-c(14, 10, 400, $c20);
        }
      }
    }
  }

  .bottom {
    width: pxTo(286);
    margin: pxTo(12) auto 0 auto;

    .btn {
      height: pxTo(38);
      border-radius: pxTo(6);
      padding: 1px;
      overflow: hidden;
      background: $bg1;
      text-align: center;

      @extend %ff-rb;
      @include fs-l-w-c(11, 36, 900, $c5);

      > div {
        width: 100%;
        height: 100%;
        background-color: $c2;
        border-radius: pxTo(5);
        text-align: center;

        @extend %ff-rb;
        @include fs-l-w-c(11, 36, 900, $c5);
      }

      &.full {
        background-color: transparent;
        color: $c2;

        > div {
          background-color: transparent;
          color: $c2;
        }
      }
    }

    .split {
      width: pxTo(8);
    }
  }
}
</style>